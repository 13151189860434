// a function that takes a number of bytes and returns a human-readable file size

export type FileSizeUnit = 'B' | 'kB' | 'MB' | 'GB' | 'TB';

const unitList = ['B', 'KB', 'MB', 'GB', 'TB'] as FileSizeUnit[];

export const getHumanFileSize = (bytes: number): `${string} ${FileSizeUnit}` => {
    if (bytes === 0) return '0 B';
    const i = Math.floor(Math.log(bytes) / Math.log(1024));
    const unit: FileSizeUnit = unitList[i];
    return `${(bytes / Math.pow(1024, i)).toFixed(0)} ${unit}`;
};