import { useRouter } from "next/router"
import { useEffect } from "react";
import { QUERY_PARAM_LOAN_ID } from 'src/constants/query-params';
import { LoanDtoExtended } from "src/services/loanApi";
import { getLoan, loanSelector } from "src/slices/loan";
import { useDispatch, useSelector } from "src/store";

export const useActiveLoan = () => {
    const { query: { [QUERY_PARAM_LOAN_ID]: loanId } } = useRouter();
    const dispatch = useDispatch();
    const loan: undefined | LoanDtoExtended = useSelector(loanSelector(String(loanId)));

    useEffect(() => {
        if (loanId && !loan) {
            dispatch(getLoan(String(loanId)));
        }
    }, [dispatch, loan, loanId]);

    return {
        // return undefined so we can set default value in the hook caller
        loan: loan !== null ? loan : undefined,
        isLoading: !loanId || !loan,
    } as const;
}