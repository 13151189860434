import { createPortal as n } from "react-dom";
const r = (t) => {
  if (t) {
    const e = document.getElementById(t);
    if (e)
      return e;
  }
  return document.body;
}, c = (t) => {
  const e = r(t.containerId);
  return n(t.children, e);
};
export {
  c as default
};
