import Bugsnag from '@bugsnag/js'
import BugsnagPluginReact from '@bugsnag/plugin-react'
import CssBaseline from '@mui/material/CssBaseline';
import { ThemeProvider } from '@mui/material/styles';
import Portal from 'components/dist/atoms/Portal';
import Tooltip from "components/dist/atoms/Tooltip";
import Router from 'next/router';
import nProgress from 'nprogress';
import React from 'react';
import { ToastContainer } from 'react-toastify';
import { AppErrorBoundary } from 'src/components/app-error-boundary';
import { AuthProvider } from 'src/contexts/auth-context';
import { isServer } from 'src/utils/is-server';

import { createTheme } from '../theme';
import { ReduxProvider } from './redux-provider';

Router.events.on('routeChangeStart', nProgress.start);
Router.events.on('routeChangeError', nProgress.done);
Router.events.on('routeChangeComplete', nProgress.done);

Bugsnag.start({
  apiKey: 'c55395ad1b6eb693f246d8a73af4251d',
  plugins: [new BugsnagPluginReact()],
})


const theme = createTheme();

// Create the error boundary...
const ErrorBoundary = Bugsnag.getPlugin('react').createErrorBoundary(React)

export const AppProvider = ({ children }) => {

  return (<ReduxProvider>
    <Tooltip.Provider>
      <AuthProvider>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          <ErrorBoundary FallbackComponent={AppErrorBoundary}>
            {children}
          </ErrorBoundary>
        </ThemeProvider>
      </AuthProvider>
      {!isServer ? <Portal>
        <div data-toast="true">
          <ToastContainer
            hideProgressBar
            autoClose={10_000}
            position="bottom-right"
            className="pointer-events-auto mb-16 md:mb-0 px-4 md:px-0"
          />
        </div>
      </Portal> : <div data-toast="true">
        <ToastContainer
          hideProgressBar
          autoClose={10_000}
          position="bottom-right"
          className="pointer-events-auto mb-16 md:mb-0 px-4 md:px-0"
        />
      </div>}
    </Tooltip.Provider>
  </ReduxProvider>)
}