import Preview from "@mui/icons-material/Preview"
import TreeView from "@mui/lab/TreeView"
import Box from "@mui/material/Box"
import CardHeader from "@mui/material/CardHeader"
import Chip from "@mui/material/Chip"
import IconButton from "@mui/material/IconButton"
import Menu from "@mui/material/Menu"
import MenuItem from "@mui/material/MenuItem"
import Radio from "@mui/material/Radio"
import Button from "components/dist/atoms/Button"
import Icon from 'components/dist/atoms/Icon';
import Separator from "components/dist/atoms/Separator"
import Stack from "components/dist/atoms/Stack"
import Text from "components/dist/atoms/Text"
import React from "react"
import type { AppUserDTO2, ElementModifierType, LoanReviewStatus, PriorityType, StorageType } from "src/backend"
import { MysherpasTooltip } from "src/components/common/mysherps-tooltip/mysherpas-tooltip.component"
import { FormElementPriorityDropdown } from "src/components/form-elements/form-element-priority-dropdown"
import { UserAvatar } from "src/components/user/user-avatar"
import { EditPencilIcon } from "src/icons/edit-pencil-icon"
import { FolderIcon } from "src/icons/folder-icon"
import { FolderOpenIcon } from "src/icons/folder-open-icon"
import { Plus } from "src/icons/plus"
import { TrashIcon } from "src/icons/trash-icon"
import { Upload } from "src/icons/upload"
import { TemplateElementTree } from "src/types/formelement"
import { isElementReviewStatusHidden } from "src/utils/form-element/is-element-review-status-hidden"
import { getInitials } from "src/utils/get-initials"
import { getUserDisplayName } from "src/utils/user/get-user-display-name"

import { ElementV2TreeStyles } from "./element-v2-tree.styles"

interface ElementV2TreeProps<T> {
  reviewStatus: LoanReviewStatus;
  expanded?: string[];
  isHiddenVisible?: boolean;
  previewTitle?: string;
  rootTitle?: string;
  hideActions?: boolean;
  totalHiddenFileRequests: number;
  totalFileRequests: number;
  expandable?: boolean;
  elementsTree: TemplateElementTree<T>[];
  onAddClick?: (element: TemplateElementTree<T>, storageType: StorageType) => void;
  onEditClick?: (element: T) => void;
  onDeleteClick?: (element: T) => void;
  onPreviewClick?: (element: T) => void;
  onPriorityChange?: (priority: PriorityType, element: T) => void;
  onAssignClick?: (element: T, event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpload?: (element: T, file: File) => void;
  onSelect?: (nodesIds: string) => void;
}

const deletePayloadInitialState = {
  anchorEl: null,
  element: null
}

export const ElementV2Tree = <T extends { visibleAtStatus?: LoanReviewStatus, hidden?: boolean, modifiers: ElementModifierType[], storageType: StorageType, answer: any, assignedToUser?: AppUserDTO2 }>({ expandable = true, ...props }: ElementV2TreeProps<T>) => {
  const [deletePayload, setDeletePayload] = React.useState<{
    anchorEl: null | HTMLElement;
    element: null | TemplateElementTree<T>;
  }>(deletePayloadInitialState);
  const [isHiddenVisible, setIsHiddenVisible] = React.useState<boolean>(props.isHiddenVisible ?? false);
  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>, element: TemplateElementTree<T>) => {
    setDeletePayload({
      anchorEl: event.currentTarget,
      element
    });
  }

  const handleDeleteMenuClose = () => {
    setDeletePayload(deletePayloadInitialState);
  }

  const handleAddStorageTypeClick = (storageType: StorageType) => {
    if (props.onAddClick && deletePayload.element) {
      props.onAddClick(deletePayload.element, storageType);
    }
    handleDeleteMenuClose();
  }

  if (!props.elementsTree) {
    return null;
  }

  return <>
    {props.previewTitle && <>
      <CardHeader
        sx={{ px: 0, py: 2 }}
        title={<>
          {props.previewTitle} <Box component='span' sx={{ color: 'error.main' }}>(Preview)</Box>
        </>} />
    </>}
    {props.totalFileRequests > 0 && <Stack row space="sm" justify="between" items="center">
      <Stack row space="sm" className="py-3 px-4" items="center">
        <Text size="sm">{props.totalFileRequests} items</Text>
        -
        <Text size="sm">{props.totalHiddenFileRequests} hidden</Text>
      </Stack>
      <MysherpasTooltip title={isHiddenVisible ? 'Hide Hidden items' : 'Show hidden items'}>
        <Button
          size="sm"
          onClick={() => setIsHiddenVisible(prevState => !prevState)}
          variant="ghost">
          <Icon name={isHiddenVisible ? "EyeOff" : "EyeAlt"} width={18} height={18} />
        </Button>
      </MysherpasTooltip>
    </Stack>}
    <Separator />
    <TreeView
      onNodeSelect={(_event, nodeId: string) => props.onSelect?.(nodeId)}
      defaultExpanded={props.expanded}
      expanded={!expandable ? props.expanded : undefined}
      defaultCollapseIcon={<>
        {!!props.onSelect && <Radio checked={false} />}
        <FolderOpenIcon />
      </>}
      defaultExpandIcon={<>
        {!!props.onSelect && <Radio checked={false} />}
        <FolderIcon />
      </>}
      defaultEndIcon={<Icon name="DocumentUpload" width={18} height={18} />}>
      {props.elementsTree
        .filter((element) => isHiddenVisible || (!element.hidden && !isElementReviewStatusHidden(element.visibleAtStatus, props.reviewStatus)))
        .map((element) => {
          return (<ElementTreeItem
            key={element.id}
            isHiddenVisible={isHiddenVisible}
            reviewStatus={props.reviewStatus}
            selectable={!!props.onSelect}
            onPreviewClick={props.onPreviewClick}
            onPriorityChange={props.onPriorityChange}
            hideActions={props.hideActions}
            element={{
              ...element,
              name: (props.rootTitle && element.storageType === 'FOLDER') ? props.rootTitle : element.name
            }}
            onAssignClick={props.onAssignClick}
            onUpload={props.onUpload}
            onEditClick={props.onEditClick}
            onDeleteClick={props.onDeleteClick}
            onAddClick={props.onAddClick ? handleAddClick : undefined} />)
        })}
    </TreeView>
    <Menu
      style={{ zIndex: 1303, pointerEvents: 'auto' }}
      anchorEl={deletePayload.anchorEl}
      open={Boolean(deletePayload.anchorEl)}
      onClose={handleDeleteMenuClose}
    >
      <MenuItem onClick={() => handleAddStorageTypeClick('FOLDER')}>Add Folder</MenuItem>
      <MenuItem onClick={() => handleAddStorageTypeClick('FILE')}>Add File Request</MenuItem>
    </Menu>
  </>
}

ElementV2Tree.displayName = 'ElementV2Tree'

interface ElementTreeItemProps<T> {
  element: TemplateElementTree<T>;
  hideActions?: boolean;
  reviewStatus?: LoanReviewStatus;
  isHiddenVisible?: boolean;
  onAddClick: (event: React.MouseEvent<HTMLButtonElement>, element: TemplateElementTree<T>) => void;
  onEditClick: (element: T) => void;
  onPreviewClick: (element: T) => void;
  onDeleteClick: (element: T) => void;
  onPriorityChange: (priority: PriorityType, element: T) => void;
  onAssignClick: (element: T, event: React.MouseEvent<HTMLButtonElement>) => void;
  onUpload: (element: T, file: File) => void;
  selectable?: boolean;
}

const ElementTreeItem = React.memo(<T extends { hidden?: boolean, modifiers: ElementModifierType[], storageType: string, answer: any, assignedToUser?: AppUserDTO2, priorityType?: PriorityType }>(props: ElementTreeItemProps<T>) => {
  const isFolder = props.element.element.storageType === 'FOLDER';
  const hasAnswer = !!props.element.element.answer;

  const handlePriorityChange = React.useCallback((priority: PriorityType) => {
    if (props.onPriorityChange) {
      props.onPriorityChange(priority, props.element.element);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.element.element, props.onPriorityChange]);

  const handleContainerClick = React.useCallback((event: React.MouseEvent) => event.stopPropagation(), []);

  const handleAddClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    props.onAddClick(event, props.element);
  };

  let finalIcon = <Icon name="ClientFolder" className="text-yellow-60" width={18} height={18} />
  if (!isFolder) {
    finalIcon = <Icon name="DocumentUpload" width={18} height={18} />
  }
  return <ElementV2TreeStyles.TreeItem
    onSelect={undefined}
    endIcon={<>
      {props.selectable && <Radio checked={false} />}
      {finalIcon}
    </>}
    key={props.element.id}
    nodeId={props.element.id}
    label={<ElementV2TreeStyles.TreeItemLabel
      onClick={handleContainerClick}>
      <ElementV2TreeStyles.TreeItemTitle>
        <Text size="sm" truncate>{props.element.name}</Text>
        {(props.element.hidden || isElementReviewStatusHidden(props.element.visibleAtStatus, props.reviewStatus)) && <MysherpasTooltip title="hidden">
          <span>
            <Icon name="EyeOff" width={20} height={20} strokeWidth={1.5} className="text-gray-neutral-100" />
          </span>
        </MysherpasTooltip>}
        {props.element.modifiers?.map(modifier => <Chip color='secondary' variant="outlined" size='small' key={modifier} label={modifier} />)}
      </ElementV2TreeStyles.TreeItemTitle>
      {!props.hideActions && <ElementV2TreeStyles.Actions>
        {(!!props.onPreviewClick && hasAnswer) && <MysherpasTooltip title='Preview uploaded document'>
          <span>
            <IconButton onClick={(event) => {
              event.stopPropagation();
              props.onPreviewClick(props.element.element);
            }}>
              <Preview />
            </IconButton>
          </span>
        </MysherpasTooltip>}
        <FormElementPriorityDropdown
          disabled={!props.onPriorityChange}
          onPriorityChange={handlePriorityChange}
          priority={props.element.element.priorityType} />
        <MysherpasTooltip title='Delete'>
          <span>
            <IconButton
              disabled={!props.onDeleteClick}
              onClick={(event) => {
                event.stopPropagation();
                props.onDeleteClick(props.element.element);
              }}>
              <TrashIcon />
            </IconButton>
          </span>
        </MysherpasTooltip>
        <MysherpasTooltip title='Edit'>
          <span>
            <IconButton
              disabled={!props.onEditClick}
              onClick={(event) => {
                event.stopPropagation();
                props.onEditClick(props.element.element);
              }}>
              <EditPencilIcon />
            </IconButton>
          </span>
        </MysherpasTooltip>
        {
          isFolder && <MysherpasTooltip title='Add'>
            <span>
              <IconButton
                disabled={!props.onAddClick}
                onClick={handleAddClick}>
                <Plus />
              </IconButton>
            </span>
          </MysherpasTooltip>
        }
        {(!isFolder && props.onUpload) && <MysherpasTooltip title='Upload file'>
          <span>
            <IconButton
              disabled={!props.onUpload}>
              <Upload />
              <ElementV2TreeStyles.FileLabel>
                <input
                  type="file"
                  onChange={(event) => {
                    if (event.target.files) {
                      props.onUpload(props.element.element, event.target.files[0]);
                    }
                  }} />
              </ElementV2TreeStyles.FileLabel>
            </IconButton>
          </span>
        </MysherpasTooltip>}
        <MysherpasTooltip title='Assign to user'>
          <span>
            <IconButton
              sx={{ opacity: !props.onAssignClick ? 0.5 : 1 }}
              disabled={!props.onAssignClick}
              onClick={(event) => {
                event.stopPropagation();
                props.onAssignClick(props.element.element, event);
              }}>
              <UserAvatar
                avatarDocId={props.element.element.assignedToUser?.avatarDocId}
                size='sm'
                role={props.element.element.assignedToUser?.loggedCompanyRole}
                userId={props.element.element.assignedToUser?.id}>
                {getInitials(getUserDisplayName(props.element.element.assignedToUser))}
              </UserAvatar>
            </IconButton>
          </span>
        </MysherpasTooltip>

      </ElementV2TreeStyles.Actions>}
    </ElementV2TreeStyles.TreeItemLabel>}>
    {props.element.children
      .filter((element) => props.isHiddenVisible || (!element.hidden && !isElementReviewStatusHidden(element.visibleAtStatus, props.reviewStatus)))
      .map((child) => {
        return <ElementTreeItem
          onPreviewClick={props.onPreviewClick}
          key={child.id}
          element={child}
          reviewStatus={props.reviewStatus}
          selectable={props.selectable}
          isHiddenVisible={props.isHiddenVisible}
          hideActions={props.hideActions}
          onUpload={props.onUpload}
          onPriorityChange={props.onPriorityChange}
          onAssignClick={props.onAssignClick}
          onDeleteClick={props.onDeleteClick}
          onEditClick={props.onEditClick}
          onAddClick={props.onAddClick} />
      })}
  </ElementV2TreeStyles.TreeItem>
});

ElementTreeItem.displayName = 'ElementTreeItem';
