import Icon from 'components/dist/atoms/Icon';
import Separator from 'components/dist/atoms/Separator';
import Stack from 'components/dist/atoms/Stack';
import Text from 'components/dist/atoms/Text';
import Tooltip from 'components/dist/atoms/Tooltip';
import ScreenSizeDropdownMenu from 'components/dist/molecules/ScreenSizeDropdownMenu';
import { FormElementStorageTypeGuard } from 'src/components/form-elements/form-element-storage-type-guard';
import { PreviewReadOnlyEdit } from 'src/components/form-elements/preview-read-only/preview-read-only-edit.component';
import { LoanRoleGuard } from 'src/components/user/loan-role-guard';
import { JestTestId } from 'src/constants/tests';
import { Permission } from 'src/constants/user';
import { getExtensionFromFilename } from 'src/utils/get-extension-from-filename';
import { classNames } from 'src/utils/tailwind-class-names';

import { FormElementStatusDropdown } from '../form-element-status-dropdown';
import { FormElementStatusPopover } from '../form-element-status-popover';
import { FormElementFileRowActionsProps } from './form-element-row.types';
import { FormElementRowAssign } from './form-element-row-assign';

export const FormElementFileRowActions = (props: FormElementFileRowActionsProps) => {

    return (<Stack
        row
        space="md"
        data-visible={props.isDropdownVisible}
        className={classNames(`lg:w-0 pl-1 group-[.is-actions-visible]:lg:w-auto
        data-[visible=true]:opacity-100 data-[visible=true]:w-auto 
            lg:group-hover:w-auto lg:group-hover:opacity-100  justify-end
            ${props.packageColumnStatus === "FULL_WIDTH" ? "absolute right-0 top-0 h-full md:pr-3 lg:pr-4 xl:pr-5" : "lg:opacity-0"}
            overflow-hidden`, {
            'min-w-6': props.packageColumnStatus === "FULL_WIDTH",
            'group-hover:bg-gray-neutral-30': !props.isActive,
            'bg-blue-50 ': props.isActive,
            // 'sm:w-auto': props.formElement.storageType === "FOLDER"
        })}>
        <FormElementStorageTypeGuard
            requiredType={'FILE'}
            type={props.formElement.storageType}>
            {!props.formElement.answer && !props.hideJitActions && <Tooltip>
                <Tooltip.Trigger>
                    <div
                        aria-label="Upload"
                        aria-hidden={props.isDropdownVisible}
                        data-ui={`${!props.isDropdownVisible ? "active" : ""}`}
                        className='hidden lg:block data-active:group-hover:opacity-100 aria-hidden:opacity-0'
                        onClick={() => {
                            props.onUploadClick();
                        }}
                    >
                        <Icon
                            strokeWidth={1.5}
                            name="Upload"
                            width={19}
                            height={19} />
                    </div>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            Upload
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        </FormElementStorageTypeGuard>
        {(props.formElement.answer &&
            !props.hideJitActions &&
            props.isUserAllowedToUpdateStatus &&
            props.formElement.isVirusClean) &&
            <LoanRoleGuard permission={Permission.AcceptDocuments}>
                <FormElementStatusPopover
                    loanRoles={props.loanRoles}
                    withPortal={!props.isNeedsListModal}
                    title={props.formElement.title}
                    loanId={props.formElement.loanId}
                    id={props.formElement.id}
                    hidden={props.isDropdownVisible}
                    status={props.formElement.status}
                />
            </LoanRoleGuard>}
        {props.formElement.answer &&
            !props.hideJitActions &&
            (!props.isLocked || props.formElement.answer?.document?.providerType === "S3") && <Tooltip>
                <Tooltip.Trigger asChild>
                    <button
                        aria-label="Open In New Tab"
                        aria-hidden={props.isDropdownVisible}
                        className='hidden lg:block aria-hidden:opacity-0 data-active:group-hover:opacity-100'
                        type="button"
                        onClick={props.onOpenNewTabClick}>
                        <Icon
                            strokeWidth={1.5}
                            name="MultiWindow" width={20} height={20} />
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            Open In New Tab
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        {!props.hideJitActions && <LoanRoleGuard
            permission={Permission.EditFormElementTitle}>
            {!props.isRoot && !props.hideJitActions && props.formElement.isVirusClean && <Tooltip>
                <Tooltip.Trigger asChild>
                    <button
                        aria-label={props.editLabel}
                        aria-hidden={props.isDropdownVisible}
                        className='hidden lg:block aria-hidden:opacity-0 data-active:group-hover:opacity-100'
                        type="button"
                        onClick={props.onEditClick}>
                        <Icon
                            strokeWidth={1.5}
                            name="Edit"
                            width={20}
                            height={20} />
                    </button>
                </Tooltip.Trigger>
                <Tooltip.Portal>
                    <Tooltip.Content
                        aria-hidden={props.isDropdownVisible}
                        align="start"
                        side="bottom"
                        alignOffset={0}
                        className="border-0 z-tooltip"
                        asChild>
                        <div className="p-2 flex rounded-md flex gap-1 items-center">
                            {props.editLabel}
                        </div>
                    </Tooltip.Content>
                </Tooltip.Portal>
            </Tooltip>}
        </LoanRoleGuard>}
        {!props.hideJitActions &&
            props.formElement.isVirusClean && <FormElementRowAssign
                loanRoles={props.loanRoles}
                loanEntities={props.loanEntities}
                formElement={props.formElement}
                loggedInUserId={props.loggedInUserId}
                isDropdownVisible={props.isDropdownVisible}
                onAssignClick={props.onAssignClick}
            />}
        <ScreenSizeDropdownMenu
            modal={false}
            open={props.isDropdownVisible}
            onOpenChange={props.onChangeDropDownVisibility}>
            <props.DropdownPortal>
                <ScreenSizeDropdownMenu.Trigger asChild>
                    <div className={`${!props.isNeedsListModal ? "fixed" : ""} h-5`}
                        style={{
                            top: props.dropdownPosition.y,
                            left: props.dropdownPosition.x
                        }} />
                </ScreenSizeDropdownMenu.Trigger>
            </props.DropdownPortal>
            <button
                data-visible={props.isDropdownVisible}
                type="button"
                disabled={props.areMultipleElementsChecked}
                aria-label="More Options"
                className={`relative z-1 ${props.areMultipleElementsChecked ? "opacity-20" : ""} group-[.is-actions-visible]:opacity-100`}
                data-id='dropdown-trigger'
                onClick={props.onDropdownTriggerClick}>
                <Icon
                    name="MoreVert"
                    width={20}
                    strokeWidth={1.5} />
            </button>
            {!props.areMultipleElementsChecked && <ScreenSizeDropdownMenu.Content
                withPortal={!props.isNeedsListModal}
                onClick={(e) => e.stopPropagation()}
                side='bottom'
                data-testid={JestTestId.FormElementDropdown}
                align='end'
                className="p-4 sm:w-72 bottom-0 gap-0">
                <FormElementStorageTypeGuard
                    requiredType={'FILE'}
                    type={props.formElement.storageType}>
                    <LoanRoleGuard
                        permission={Permission.EditFormElementTitle}>
                        {!props.isRoot && <ScreenSizeDropdownMenu.Item
                            className="h-12 shrink-0"
                            onSelect={props.onEditDetailsClick}>
                            <Stack
                                row
                                space="sm"
                                className="items-center w-full cursor-pointer">
                                <Icon
                                    strokeWidth={1.5}
                                    name="EditPencil"
                                    width={18}
                                    height={18} />
                                <Text size="sm">
                                    Edit details
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>}
                    </LoanRoleGuard>
                </FormElementStorageTypeGuard>
                <ScreenSizeDropdownMenu.Item
                    className="h-12 shrink-0 cursor-pointer w-full"
                    onSelect={props.onOpenNewTabClick}>
                    <Stack
                        row
                        space="sm"
                        className="items-center">
                        <Icon
                            strokeWidth={1.5}
                            name="MultiWindow"
                            width={18}
                            height={18} />
                        <Text size="sm">
                            {props.formElement.storageType === 'FILE' && 'Open In New Tab'}
                            {props.formElement.storageType === 'FOLDER' && 'Open Folder in New Tab'}
                        </Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
                <LoanRoleGuard permission={Permission.PinFormElements}>
                    {!props.isRoot && !props.isNeedsListModal && <ScreenSizeDropdownMenu.Item className="h-12 shrink-0" onSelect={props.onPinClick}>
                        <Stack row space="sm" className="items-center w-full cursor-pointer" >
                            <Icon
                                strokeWidth={1.5}
                                name={props.isPinned ? "RemovePin" : "Pin"}
                                width={18}
                                height={18} />
                            <Text size="sm">{props.isPinned ? "Unpin" : "Pin"}</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>}
                </LoanRoleGuard>
                {!props.isUploadDisabled && <ScreenSizeDropdownMenu.Item
                    onSelect={() => props.onUploadClick()}
                    className='h-12 shrink-0'
                >
                    <Stack
                        row
                        space="sm"
                        className="items-center w-full cursor-pointer">
                        <Icon
                            strokeWidth={1.5}
                            name="Upload"
                            width={19}
                            height={19} />
                        <Text size="sm">Upload</Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>}
                <ScreenSizeDropdownMenu.Item className="h-12 shrink-0"
                    onSelect={() => props.onAssignClick()} >
                    <Stack
                        row
                        space="sm"
                        className="items-center cursor-pointer w-full">
                        <Icon
                            strokeWidth={1.5}
                            name="AddUser"
                            width={20}
                            height={20} />
                        <Text size="sm">Assign</Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
                {props.formElement.answer?.document?.providerType === 'SHAREPOINT' &&
                    props.formElement.isVirusClean &&
                    <ScreenSizeDropdownMenu.Sub>
                        <ScreenSizeDropdownMenu.SubTrigger >
                            <Stack
                                row
                                space="sm"
                                className='flex-1'
                            >
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center px-1 cursor-pointer w-full text-sm text-black-primary">
                                    <PreviewReadOnlyEdit
                                        platform='DESKTOP'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </Stack>
                            </Stack>
                        </ScreenSizeDropdownMenu.SubTrigger>
                        <ScreenSizeDropdownMenu.Portal>
                            <ScreenSizeDropdownMenu.SubContent
                                withPortal={!props.isNeedsListModal}
                                className="py-2 px-0 gap-0 sm:gap-2 sm:p-2 sm:w-72 overflow-none">
                                <ScreenSizeDropdownMenu.Item
                                    className="h-12 gap-2"

                                    onSelect={() => props.onEditOneDriveFile("DESKTOP")}>
                                    <PreviewReadOnlyEdit
                                        platform='DESKTOP'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </ScreenSizeDropdownMenu.Item>
                                <ScreenSizeDropdownMenu.Item
                                    className="h-12 gap-2"
                                    onSelect={() => props.onEditOneDriveFile("WEB")}>
                                    <PreviewReadOnlyEdit
                                        platform='WEB'
                                        extension={getExtensionFromFilename(props.formElement.answer.document.name)} />
                                </ScreenSizeDropdownMenu.Item>
                            </ScreenSizeDropdownMenu.SubContent>
                        </ScreenSizeDropdownMenu.Portal>
                    </ScreenSizeDropdownMenu.Sub>}
                {!props.isNeedsListModal && (
                    <ScreenSizeDropdownMenu.Item className="h-12 shrink-0"
                        onSelect={props.onToggleVisibility}>
                        <Stack row space="sm" className="items-center cursor-pointer w-full"
                        >
                            <Icon
                                strokeWidth={1.5}
                                name={props.formElement.hidden ? "EyeAlt" : "EyeOff"}
                                width={20}
                                height={20} />
                            <Text size="sm">{props.formElement.hidden ? "Show" : "Hide"}</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>
                )}
                <LoanRoleGuard permission={Permission.BorrowerTeamCommunication}>
                    <ScreenSizeDropdownMenu.Item className="h-12 shrink-0" onSelect={() => props.onSendMessage(props.formElement)}>
                        <Stack row space="sm" className="items-center cursor-pointer w-full">
                            <Icon
                                strokeWidth={1.5}
                                name="MessageText"
                                width={20}
                                height={20} />
                            <Text size="sm">Send a message</Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>
                </LoanRoleGuard>
                <LoanRoleGuard
                    permission={Permission.EditFormElementTitle}>
                    {!props.isRoot && <ScreenSizeDropdownMenu.Item
                        className="h-12 shrink-0"
                        onSelect={props.onEditClick}>
                        <Stack
                            row
                            space="sm"
                            className="items-center w-full cursor-pointer">
                            <Icon
                                strokeWidth={1.5}
                                name="Edit"
                                width={18}
                                height={18} />
                            <Text size="sm">
                                {props.editLabel}
                            </Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>}
                </LoanRoleGuard>

                {(!props.isLocked || props.formElement.answer?.document?.providerType === "S3") && <FormElementStorageTypeGuard
                    requiredType={"FILE"}
                    type={props.formElement.storageType}>
                    <LoanRoleGuard permission={Permission.AcceptDocuments}>
                        {props.formElement.answer && props.formElement.isVirusClean && props.isUserAllowedToUpdateStatus && <FormElementStatusDropdown
                            title={props.formElement.title}
                            loanId={props.formElement.loanId}
                            loanRoles={props.loanRoles}
                            id={props.formElement.id}
                            hidden={props.isDropdownVisible}
                            status={props.formElement.status}
                        />}
                    </LoanRoleGuard>
                    <Separator />
                </FormElementStorageTypeGuard>}
                {
                    props.formElement.answer
                    && (!props.isLocked || props.formElement.answer?.document?.providerType === "S3")
                    && <FormElementStorageTypeGuard
                        type={props.formElement.storageType}
                        requiredType={'FILE'}>
                        <ScreenSizeDropdownMenu.Item className="h-12 shrink-0"
                            disabled={!props.formElement.isVirusClean}
                            onSelect={props.onDownloadClick}>
                            <Stack row space="sm" className="items-center cursor-pointer w-full" >
                                <Icon
                                    name="DownloadMenu"
                                    width={18}
                                    height={18} />
                                <Text size="sm">Download</Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>
                    </FormElementStorageTypeGuard>
                }
                <LoanRoleGuard
                    permission={Permission.MoveElements}>
                    <ScreenSizeDropdownMenu.Item
                        disabled={!props.formElement.isVirusClean}
                        onSelect={props.onMoveToClick}
                        className='h-12 shrink-0'>
                        <Stack row space='sm'>
                            <Icon
                                name="FolderMoveUp"
                                width={21}
                                height={18} />
                            <Text as='span' size='sm'>
                                Move to...
                            </Text>
                        </Stack>
                    </ScreenSizeDropdownMenu.Item>
                </LoanRoleGuard>
                <ScreenSizeDropdownMenu.Item
                    disabled={!props.formElement.isVirusClean}
                    onSelect={props.onCopyToClick}
                    className='h-12 shrink-0'>
                    <Stack row space='sm'>
                        <Icon
                            strokeWidth={1.5}
                            name="Copy"
                            width={20}
                            height={20} />
                        <Text as='span' size='sm' >
                            Copy to...
                        </Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>
                <ScreenSizeDropdownMenu.Item
                    onSelect={() => props.onCopyClick(props.formElement.id)}
                    className='h-12 shrink-0'>
                    <Stack row space='sm'>
                        <Icon
                            strokeWidth={1.5}
                            name="Link"
                            width={19}
                            height={19} />
                        <Text as='span' size='sm' >
                            Copy Link
                        </Text>
                    </Stack>
                </ScreenSizeDropdownMenu.Item>

                {
                    props.isDeleteAnswerAllowed && <FormElementStorageTypeGuard
                        type={props.formElement.storageType}
                        requiredType={'FILE'}>
                        <Separator />
                        {props.formElement.answer && <ScreenSizeDropdownMenu.Item
                            onSelect={props.onDeleteAnswerClick}
                            disabled={!props.formElement.answer}
                            className="h-12 shrink-0">
                            <Stack
                                row
                                space="sm"
                                className="items-center cursor-pointer"
                            >
                                <Icon
                                    name="Trash"
                                    width={18}
                                    height={18}
                                    className="text-destructive"
                                />
                                <Text size="sm" variant="destructive">
                                    Delete Answer
                                </Text>
                            </Stack>
                        </ScreenSizeDropdownMenu.Item>}
                    </FormElementStorageTypeGuard>
                }
                <LoanRoleGuard permission={Permission.DeleteFormElements}>
                    {!props.isDeleteDisabled && <>
                        <FormElementStorageTypeGuard
                            type={props.formElement.storageType}
                            requiredType={'FILE'}>
                            <ScreenSizeDropdownMenu.Item className="h-12 shrink-0" onSelect={props.onDeleteElementClick}>
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center cursor-pointer"
                                >
                                    <Icon
                                        name="Trash"
                                        width={18}
                                        height={18}
                                        className="text-destructive"
                                    />
                                    <Text size="sm" variant="destructive">
                                        Delete File Request
                                    </Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                        </FormElementStorageTypeGuard>
                        <FormElementStorageTypeGuard
                            type={props.formElement.storageType}
                            requiredType={'FOLDER'}>
                            <ScreenSizeDropdownMenu.Item className='h-12 shrink-0' onSelect={props.onDeleteElementClick}>
                                <Stack
                                    row
                                    space="sm"
                                    className="items-center cursor-pointer flex-1" >
                                    <Icon
                                        name="Trash"
                                        width={18}
                                        height={18}
                                        className="text-destructive"
                                    />
                                    <Text size="sm" variant='destructive'>
                                        Delete
                                    </Text>
                                </Stack>
                            </ScreenSizeDropdownMenu.Item>
                        </FormElementStorageTypeGuard>
                    </>}
                </LoanRoleGuard>
            </ScreenSizeDropdownMenu.Content >}
        </ScreenSizeDropdownMenu>
    </Stack >
    );

};